import { UserState } from './user-state.enum';
import { DriversLicense } from './drivers-license.class';
import Address from '@/shared/types/address.class';
import { Account } from '../accounts/account.class';
import { BaseModel } from '@/shared/types/base.model';
import { Role } from '../auth/role.enum';
import { Project } from '../projects/project.model';
import { UserPaymentStatus } from './user-payment-status.enum';

export class User extends BaseModel {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public state?: UserState,
    public role?: Role,
    public project?: Project,
    public projects?: Project[],
    public company?: string,
    public mobilePhoneNumber?: string,
    public languageCode?: string,
    public driversLicense?: DriversLicense,
    public address?: Address,
    public paymentStatus: UserPaymentStatus = UserPaymentStatus.new,
    public paymentProviderId?: string,
    public mandateId?: string,
    public canBook: boolean = true,
    public suspendAfter?: Date,
  ) {
    super();
  }
}
