

































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import moment from 'moment';
import * as _ from 'lodash';

import BaseForm from '../components/BaseForm.vue';
import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import BookingForm from '../components/BookingForm.vue';

import { Column } from '../shared/types/column.class';
import {
  formatDate,
  formatAssetDate,
  formatUser,
  formatPrice,
} from '../shared/util/print-column.util';
import { CastType, Filter, Operator } from '../shared/types/filter.class';

import { Booking } from '../api/bookings/booking.model';
import { SortOrder } from '../shared/types/sort-order.enum';
import { Prop } from 'vue-property-decorator';
import { PaginateResult } from '../shared/types/paginate-result.class';
import { Project } from '@/api/projects/project.model';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '@/shared/types/form-element.class';
import { User } from '@/api/users/user.class';
import { Role } from '@/api/auth/role.enum';

const bookingsModule = namespace('bookings');
const projectsModule = namespace('projects');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, BookingForm, BaseForm } })
export default class BookingsTable extends Vue {
  @Prop({
    required: true,
  })
  filter!: Filter;

  @Prop({
    type: Array,
  })
  columns!: Column[];

  @Prop({
    default: true,
  })
  canUpdate!: boolean;

  @Prop({
    default: true,
  })
  canDelete!: boolean;

  @Prop({
    default: true,
  })
  canSearch!: boolean;

  @Prop()
  embedded!: boolean;

  showFilters = false;
  isLoading = false;
  localFilter = { project: [] };
  filterElements: FormElement[][] = [];

  baseColumns: Column[] = [
    new Column('asset.name', 'Asset'),
    new Column('start', 'Start', formatAssetDate),
    new Column('end', 'End', formatAssetDate),
    new Column('user', 'User', formatUser),
    new Column('project', 'Project', this.printProject.bind(this)),
    new Column('createdAt', 'Created At', formatDate),
    new Column('state', 'State'),
    new Column('calculatedPrice', 'Price', formatPrice),
    new Column('fine', 'Fine', formatPrice),
  ];

  printProject(column: Column, obj: unknown) {
    const value = _.get(obj, column.field);
    const project = this.projects.find(proj => proj._id === value);

    return project?.name || 'N/A';
  }

  get getColumns() {
    if (!this.columns || this.columns.length === 0) {
      return this.baseColumns;
    }
    return this.columns;
  }

  get getCanDelete() {
    return this.canDelete && this.$can('delete', 'Booking');
  }

  get getCanUpdate() {
    return this.canUpdate && this.$can('update', 'Booking');
  }

  @usersModule.Getter('loggedInUser')
  user!: User;

  @bookingsModule.Getter('all')
  bookings!: Booking[];

  @projectsModule.Getter('full')
  projects!: Project[];

  @bookingsModule.Getter('pagination')
  pagination!: PaginateResult<Booking>;

  @bookingsModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Booking[]>;

  @projectsModule.Action('fetchFull')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @bookingsModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  get showFilterButton() {
    if (
      (this.user.role === Role.SuperAdmin ||
        this.user.role === Role.CompanyAdmin) &&
      !this.embedded
    ) {
      return true;
    }
    return false;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAllProjects();
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  openDetail(booking: Booking) {
    this.$router.push({ name: 'BookingDetail', params: { id: booking._id } });
  }

  async doDelete(booking: Booking) {
    await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove the selected booking?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    this.delete(booking._id);
  }

  onFilterClicked() {
    this.filterElements = [
      [
        new FormElement(
          'project',
          'Project',
          new FormElementOptions(this.projects, 'name', '_id'),
          '',
          FormElementType.SELECT,
        ),
      ],
    ];
    this.showFilters = true;
  }

  applyFilters() {
    this.filter.removeForKeyAndOperator('project', Operator.Equal);
    if (this.localFilter.project.length > 0) {
      this.filter.addFilter(
        'project',
        this.localFilter.project,
        Operator.Equal,
        CastType.ObjectId,
      );
    }
    this.doFetchAll();
  }

  async created() {
    this.doFetchAll();
  }
}
