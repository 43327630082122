





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { User } from '../api/users/user.class';
import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { namespace } from 'vuex-class';
import { Company } from '../api/companies/company.model';
import { Project } from '../api/projects/project.model';
import { Filter } from '../shared/types/filter.class';
import { Role } from '../api/auth/role.enum';
import { UserState } from '../api/users/user-state.enum';

const companiesModule = namespace('companies');
const projectsModule = namespace('projects');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class UserForm extends Vue {
  @Prop()
  user!: User;

  formElements: FormElement[][] = [
    [
      new FormElement(
        'firstName',
        'First name',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'lastName',
        'Last name',
        new FormElementOptions(),
        'required',
      ),
    ],
    [
      new FormElement(
        'email',
        'Email',
        new FormElementOptions(),
        'required|email',
      ),
      new FormElement(
        'mobilePhoneNumber',
        'Phone',
        new FormElementOptions(),
        'required',
      ),
    ],
    [
      new FormElement(
        'driversLicense.front',
        'Drivers License (F) - TIJDELIJK UITGESCHAKELD',
        new FormElementOptions(),
        '',
        FormElementType.IMAGE,
      ),
      new FormElement(
        'driversLicense.back',
        'Drivers License (B) - TIJDELIJK UITGESCHAKELD',
        new FormElementOptions(),
        '',
        FormElementType.IMAGE,
      ),
    ],
  ];
}
