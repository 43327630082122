















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import UserForm from '../components/UserForm.vue';
import BaseForm from '../components/BaseForm.vue';

import { Column } from '../shared/types/column.class';
import { Filter, FilterOperation } from '../shared/types/filter.class';
import { SortOrder } from '../shared/types/sort-order.enum';
import moment from 'moment';

import { User } from '../api/users/user.class';
import { UserState } from '../api/users/user-state.enum';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { Project } from '../api/projects/project.model';
import { Company } from '../api/companies/company.model';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '../shared/types/form-element.class';
import { Invite } from '../api/users/invite.model';
import { Role } from '../api/auth/role.enum';
import { PaginateResult } from '../shared/types/paginate-result.class';

const usersModule = namespace('users');
const appModule = namespace('app');

const companiesModule = namespace('companies');
const projectsModule = namespace('projects');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, UserForm, BaseHeader, BaseForm } })
export default class Users extends Vue {
  $refs!: Vue['$refs'] & {
    userDetailModal: BModal;
    inviteUserModal: BModal;
  };

  tabIndex = 0;

  columns: Column[] = [
    new Column('firstName', 'First Name'),
    new Column('lastName', 'Last Name'),
    new Column('email', 'Email'),
    new Column('role', 'Role', this.printRole),
    new Column('state', 'State'),
  ];

  inviteFormElements: FormElement[][] = [];

  userForDetail: User = new User();

  filter: Filter = new Filter();
  invitedUserData: Invite = new Invite();

  @usersModule.Getter('loggedInUser')
  loggedInUser!: User;

  @projectsModule.Getter('all')
  projects!: Project[];

  @usersModule.Getter('pagination')
  pagination!: PaginateResult<User>;

  @companiesModule.Getter('all')
  companies!: Company[];

  @projectsModule.Action('fetchAll')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @companiesModule.Action('fetchAll')
  fetchAllCompanies!: (filter?: Filter) => Promise<Company[]>;

  @usersModule.Getter('all')
  allUsers!: User[];

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @usersModule.Action('fetchAll')
  fetchAllUsers!: (filter?: Filter) => Promise<User[]>;

  @usersModule.Action('update')
  update!: (user: User) => Promise<User>;

  @usersModule.Action('delete')
  deleteUser!: (id?: string) => Promise<void>;

  @usersModule.Action('acceptUser')
  acceptUser!: (user: User) => Promise<User>;

  @usersModule.Action('inviteUsers')
  inviteUsers!: (invite: Invite) => Promise<void>;

  get editOKTitle() {
    if (this.userForDetail.state == UserState.Pending) {
      return 'Accept user';
    }
    return 'Ok';
  }

  get availableRoles() {
    const role = this.loggedInUser?.role || Role.User;
    const index = Object.values(Role).indexOf(role);
    return Object.values(Role).slice(index);
  }

  get editOKVariant() {
    if (this.userForDetail.state == UserState.Pending) {
      return 'warning';
    }
    return 'primary';
  }

  printRole(column: Column, user: User) {
    return this.$t(user.role);
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.fetchAllUsers(this.filter);
  }

  async updateUser(user: User) {
    if (user.state != UserState.Pending) {
      await this.update(user);
    } else {
      await this.acceptUser(user);
    }
    this.fetchAllUsers(this.filter);
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.fetchAllUsers(this.filter);
  }

  @Watch('invitedUserData.company')
  async onInvitedCompanyChanged(newValue: string) {
    await this.fetchAllProjects(
      new Filter([new FilterOperation('company', newValue)]),
    );
    const projectsIndex = this.inviteFormElements.length === 3 ? 1 : 0;
    this.inviteFormElements[
      projectsIndex
    ][0].options.optionsData = this.projects;
  }

  @Watch('tabIndex')
  onTabChanged(tabIndex: number) {
    this.filter.removeForKey('state');
    if (tabIndex === 1) {
      this.filter.addFilter('state', UserState.Active);
    }
    if (tabIndex === 2) {
      this.filter.addFilter('state', UserState.Pending);
    }
    if (tabIndex === 3) {
      this.filter.addFilter('state', UserState.Invited);
    }
    this.fetchAllUsers(this.filter);
  }

  async doInvite(invite: Invite) {
    try {
      invite.projects = [invite.project];
      console.log(invite);
      await this.inviteUsers(invite);
      this.clearEmails();
      this.fetchAllUsers(this.filter);
      this.addToast(new Toast('Users invited', ToastType.SUCCESS));
    } catch (e) {
      this.addToast(new Toast(e.message, ToastType.ERROR));
      return false;
    }
  }

  doSort(field: string) {
    if (!this.filter.sort) {
      this.filter.sort = {};
    }
    const currentOrder: SortOrder = this.filter.sort[field];
    this.filter.sort = {};
    if (!currentOrder || currentOrder === SortOrder.Desc) {
      this.filter.sort[field] = SortOrder.Asc;
    } else if (currentOrder === SortOrder.Asc) {
      this.filter.sort[field] = SortOrder.Desc;
    }
    this.fetchAllUsers(this.filter);
  }

  openInviteModal() {
    this.invitedUserData.suspendAfter = moment()
      .add(1, 'year')
      .endOf('day')
      .toDate();
    this.$refs.inviteUserModal.show();
  }

  openUserDetail(user: User) {
    this.$router.push({ name: 'UserDetail', params: { id: user._id } });
  }

  async doDelete(user: User) {
    const value = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${user.email}?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        // okTitle: "YES",
        centered: true,
      },
    );
    if (!value) {
      return;
    }
    await this.deleteUser(user._id);
    this.fetchAllUsers(this.filter);
  }

  clearEmails() {
    this.invitedUserData = new Invite();
  }

  mounted() {
    const openCreate = this.$route.query.openCreate;
    if (openCreate) {
      this.openInviteModal();
    }
  }

  async created() {
    await this.fetchAllUsers(this.filter);
    this.tabIndex = parseInt(this.$route.query.tab as string);
    if (this.$can('read', 'Company')) {
      await this.fetchAllCompanies();
      await this.fetchAllProjects();
      this.inviteFormElements.push([
        new FormElement(
          'company',
          'Company',
          new FormElementOptions(this.companies, 'name', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ]);
      this.inviteFormElements.push([
        new FormElement(
          'project',
          'Project',
          new FormElementOptions(this.projects, 'name', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ]);
    }
    this.inviteFormElements.push([
      new FormElement(
        'role',
        'Role',
        new FormElementOptions(this.availableRoles),
        'required',
        FormElementType.SELECT,
      ),
    ]);
  }
}
