import moment from 'moment';
export function printDuration(date1: Date, date2: Date | string) {
  const diff = moment(date2).diff(moment(date1));
  const duration = moment.duration(diff);
  const days = Math.floor(Math.abs(duration.asDays()));
  const hours = Math.floor(Math.abs(duration.asHours() % 24));
  const minutes = Math.ceil(Math.abs(duration.asMinutes() % 60));
  const minutesTotal = duration.asMinutes();
  let str = '';
  if (minutesTotal < 0) {
    str = '-';
  }
  if (days !== 0) {
    str += `${days}d `;
  }
  if (hours !== 0) {
    str += `${hours}h `;
  }
  if (minutes !== 0) {
    str += `${minutes}m `;
  }
  return str;
}
