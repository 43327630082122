
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Role } from '../../api/auth/role.enum';
import { Company } from '../../api/companies/company.model';
import { Project } from '../../api/projects/project.model';
import { UserState } from '../../api/users/user-state.enum';
import { User } from '../../api/users/user.class';
import { Filter } from '../../shared/types/filter.class';
import moment from 'moment-timezone';

import GeneralInformationListItem from '../GeneralInformationListItem.vue';

const companiesModule = namespace('companies');
const projectsModule = namespace('projects');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { GeneralInformationListItem },
})
export default class GeneralInformation extends Vue {
  colorMapping = {
    [UserState.Pending]: 'secondary',
    [UserState.Invited]: 'info',
    [UserState.Active]: 'primary',
    [UserState.Blocked]: 'dark',
  };

  @Prop()
  user!: User;

  get isSuperAdmin() {
    return this.$can('is', Role.SuperAdmin);
  }

  get isCompanyAdmin() {
    return this.$can('is', Role.CompanyAdmin);
  }

  get state() {
    return this.user?.state;
  }

  get stateVariant() {
    return this.colorMapping[this.state];
  }

  get userName() {
    if (this.user) {
      return `${this.user?.firstName} ${this.user?.lastName}`;
    }
    return 'N/A';
  }

  get createdAt() {
    return moment.utc(this.user?.createdAt).format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get updatedAt() {
    return moment.utc(this.user?.updatedAt).format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get availableRoles() {
    const role = this.loggedInUser?.role || Role.User;
    const index = Object.values(Role).indexOf(role);
    return Object.values(Role).slice(index);
  }

  get paymentProviderId() {
    return this.user.paymentProviderId || 'N/A';
  }

  get paymentProviderUrl() {
    if (!this.user.paymentProviderId) {
      return false;
    }
    return `https://www.mollie.com/dashboard/customers/${this.user.paymentProviderId}`;
  }

  get mandateId() {
    return this.user.mandateId || 'N/A';
  }

  get canReadCompany() {
    return this.$can('read', 'Company');
  }

  get canReadProject() {
    return this.$can('read', 'Project');
  }

  @usersModule.Getter('loggedInUser')
  loggedInUser!: User;

  @projectsModule.Getter('all')
  projects!: Project[];

  @companiesModule.Getter('all')
  companies!: Company[];

  @projectsModule.Action('fetchAll')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @companiesModule.Action('fetchAll')
  fetchAllCompanies!: (filter?: Filter) => Promise<Company[]>;

  openPayment() {
    window.alert('OPEN PAY');
  }

  async created() {
    if (this.canReadCompany) {
      await this.fetchAllCompanies();
    }
    if (this.canReadProject) {
      await this.fetchAllProjects();
    }
  }
}
