





























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Booking } from '../api/bookings/booking.model';
import { Payment } from '../api/payments/payment.model';
import PaymentsService from '../api/payments/payments.service';
import { UserState } from '../api/users/user-state.enum';
import { User } from '../api/users/user.class';
import UsersService from '../api/users/users.service';
import BaseHeader from '../components/BaseHeader.vue';
import BookingsTable from '../components/BookingsTable.vue';
import LogsTable from '../components/LogsTable.vue';
import PaymentsTable from '../components/PaymentsTable.vue';
import PersonalKeysTable from '../components/PersonalKeysTable.vue';
import SupportTable from '../components/SupportTable.vue';
import GeneralInformation from '../components/UserDetail/GeneralInformation.vue';
import UserForm from '../components/UserForm.vue';
import { Column } from '../shared/types/column.class';
import { Filter } from '../shared/types/filter.class';
import { SortOrder } from '../shared/types/sort-order.enum';
import { ToastType } from '../shared/types/toast-type.enum';
import Toast from '../shared/types/toast.class';
import { formatAssetDate } from '../shared/util/print-column.util';

const usersModule = namespace('users');
const appModule = namespace('app');
const authModule = namespace('auth');

const usersService = new UsersService();
const paymentsService = new PaymentsService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseHeader,
    GeneralInformation,
    UserForm,
    SupportTable,
    BookingsTable,
    PaymentsTable,
    LogsTable,
    PersonalKeysTable,
  },
})
export default class UserDetail extends Vue {
  bookingsFilter = new Filter([], { start: SortOrder.Asc });
  paymentsFilter = new Filter();
  personalKeysFilter = new Filter();

  detailTabs = ['Info', 'Bookings', 'Payments', 'Personal keys', 'Support'];
  detailTab = 'Info';
  currentLogTab = 'all';

  get userName() {
    return `${this.user?.firstName} ${this.user?.lastName}`;
  }

  get nextActionVariant() {
    if (this.isBlocked) {
      return 'warning';
    }
    return 'info';
  }

  get nextActionIcon() {
    if (this.isBlocked) {
      return 'user-check';
    }
    if (this.isInvited) {
      return 'send';
    }
    return 'unlock';
  }

  get nextAction() {
    if (this.isBlocked) {
      return 'Unblock';
    }
    if (this.isInvited) {
      return 'Re-send invitation';
    }
    return 'Request password reset';
  }

  get isPersonalActive() {
    return this.detailTab === this.detailTabs[0];
  }

  get isBookingsActive() {
    return this.detailTab === this.detailTabs[1];
  }

  get isPaymentsActive() {
    return this.detailTab === this.detailTabs[2];
  }

  get isPersonalKeysActive() {
    return this.detailTab === this.detailTabs[3];
  }

  get isSupportActive() {
    return this.detailTab === this.detailTabs[4];
  }

  get isActive() {
    return this.user.state === UserState.Active;
  }

  get isInvited() {
    return this.user.state === UserState.Invited;
  }

  get isBlocked() {
    return this.user.state === UserState.Blocked;
  }

  get detailsBodyClass() {
    if (this.isPersonalActive) {
      return '';
    }
    return 'p-0';
  }

  formatAssetDateForPayment(column: Column, payment: Payment) {
    return formatAssetDate(column, payment.booking);
  }

  printStatus(column: Column, payment: Payment) {
    return this.$t(`paymentStatus.${payment.status}`);
  }

  @usersModule.Getter('current')
  user!: User;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @usersModule.Action('fetchById')
  fetchById!: (id: string) => Promise<User>;

  @usersModule.Action('update')
  update!: (user: User) => Promise<User>;

  @usersModule.Action('block')
  block!: (id: string) => Promise<User>;

  @usersModule.Action('unblock')
  unblock!: (id: string) => Promise<User>;

  @usersModule.Action('delete')
  delete!: (id: string) => Promise<User>;

  @authModule.Action('requestResetPassword')
  requestResetPassword!: (email: string) => Promise<void>;

  onLogsTabChanged(tab: string) {
    this.currentLogTab = tab;
  }

  async doSave() {
    this.addLoader('app');
    await this.update(this.user);
    this.removeLoader('app');
  }

  async resendInvite() {
    this.addLoader('app');
    const toast = new Toast('Invite sent', ToastType.SUCCESS);
    try {
      await usersService.resendInvite(this.user._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.addLoader('app');
  }

  async doUnblock() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to ACTIVATE ${this.user.email}?`,
      {
        title: 'Apply',
        okVariant: 'primary',
        centered: true,
      },
    );
    if (!result) return;
    this.addLoader('app');
    const toast = new Toast('User activated', ToastType.SUCCESS);
    try {
      await this.unblock(this.user._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doBlock() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to BLOCK ${this.user.email}?`,
      {
        title: 'Apply',
        okVariant: 'primary',
        centered: true,
      },
    );
    if (!result) return;
    this.addLoader('app');
    const toast = new Toast('User blocked', ToastType.SUCCESS);
    try {
      await this.block(this.user._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doRevokeMandate() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to revoke the mandate for ${this.user.email}?`,
      {
        title: 'Revoke',
        okVariant: 'danger',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Mandate revoked', ToastType.SUCCESS);
    try {
      await paymentsService.revokeMandate(this.user._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doDelete() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to delete ${this.user.email}?`,
      {
        title: 'Delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('User deleted', ToastType.SUCCESS);
    try {
      await this.delete(this.user._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
    this.$router.go(-1);
  }

  doNextAction() {
    if (this.isBlocked) {
      return this.doUnblock();
    }
    if (this.isInvited) {
      return this.resendInvite();
    }
    return this.doPasswordReset();
  }

  async doPasswordReset() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to request a password reset for ${this.user.email}?`,
      {
        title: 'Request',
        okVariant: 'primary',
        centered: true,
      },
    );
    if (!result) return;
    this.addLoader('app');
    const toast = new Toast('Password reset mail sent', ToastType.SUCCESS);
    try {
      await this.requestResetPassword(this.user.email);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  openBooking(booking: Booking) {
    this.$router.push({ name: 'BookingDetail', params: { id: booking._id } });
  }

  async created() {
    this.addLoader('app');
    const user = this.$route.params.id;
    this.bookingsFilter.addFilter('user', user);
    this.paymentsFilter.addFilter('user', user);
    this.personalKeysFilter.addFilter('user', user);
    await this.fetchById(user);
    this.removeLoader('app');
  }
}
