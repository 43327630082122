





























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import BookingForm from '../components/BookingForm.vue';

import { Column } from '../shared/types/column.class';
import { CastType, Filter, Operator } from '../shared/types/filter.class';

import { Prop, Watch } from 'vue-property-decorator';
import { PaginateResult } from '../shared/types/paginate-result.class';
import { Log } from '@/api/logs/log.model';
import { formatDate } from '@/shared/util/print-column.util';
import { SortOrder } from '@/shared/types/sort-order.enum';

const logsModule = namespace('logs');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, BookingForm } })
export default class LogsTable extends Vue {
  @Prop()
  subject!: string;

  @Prop()
  subjectType!: string;

  filter: Filter = new Filter();

  tab = 0;

  isLoading = false;

  actionToFormat = {
    ActionKey: this.formatActionKey.bind(this),
  };

  columns: Column[] = [
    new Column('subject', 'Action', undefined, true, false, 'no-wrap'),
    new Column('type', 'Action', undefined, true, false, 'no-wrap'),
    new Column(
      'body',
      'Data',
      this.formatBody.bind(this),
      true,
      false,
      'w-50',
      true,
    ),
    new Column('createdAt', 'Created at', formatDate, true, false, 'no-wrap'),
  ];

  @logsModule.Getter('all')
  logs!: Log[];

  @logsModule.Getter('pagination')
  pagination!: PaginateResult<Log>;

  @logsModule.Action('fetchAll')
  fetchLogs!: (filter: Filter) => Promise<PaginateResult<Log>>;

  @Watch('tab')
  async onTabChanged() {
    this.doFetchAll();
  }

  @Watch('subject', { immediate: true })
  async doFetchAll() {
    if (!this.subject) {
      return;
    }
    this.isLoading = true;
    this.adjustFilter();
    await this.fetchLogs(this.filter);
    this.isLoading = false;
  }

  adjustFilter() {
    this.filter.sort = { createdAt: SortOrder.Asc };
    this.filter.replaceFilter('limit', 10);
    this.filter.removeForKey('subjectId');
    this.filter.removeForKey('subject');
    this.filter.removeForKey(this.subjectType.toLowerCase());
    if (this.tab === 0) {
      this.filter.addFilter(
        'subjectId',
        this.subject,
        Operator.Equal,
        CastType.ObjectId,
      );
      this.filter.addFilter('subject', this.subjectType);
    } else {
      this.filter.addFilter(
        this.subjectType.toLowerCase(),
        this.subject,
        Operator.Equal,
        CastType.ObjectId,
      );
      this.filter.addFilter('subject', 'ActionKey');
    }
  }

  formatBody(column: Column, data: Log) {
    const subject = data.subject;
    if (!data.body) {
      return '';
    }

    if (!subject) {
      return JSON.parse(data.body);
    }

    const formatter = this.actionToFormat[subject];
    if (!formatter) {
      return this.formatLog(data);
    }

    return formatter(data);
  }

  valueToString(value: any) {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object' && value._id) {
      return value._id;
    }
    return value;
  }

  bodyToString(body: any) {
    if (typeof body === 'string') {
      return body;
    }
    const items = Object.keys(body)
      .map((key: string) => `<li>${key}: ${this.valueToString(body[key])}</li>`)
      .join('');
    return `<ul>${items}</ul>`;
  }

  performedBy(data: any) {
    if (!data.performedBy) {
      return '';
    }
    return `<p>Performed by user: <a target="_blank" href="/users/${data.performedBy}">${data.performedBy}</a></p>`;
  }

  formatLog(data: any) {
    const body = JSON.parse(data.body);
    return `${this.performedBy(data)}${this.bodyToString(body)}`;
  }

  formatActionKey(data: any) {
    const body = JSON.parse(data.body);
    return `User <a target="_blank" href="/users/${body.user}">${body.user}</a> sent command <b>${body.command}</b> to keywi <a target="_blank" href="/keywis/${body.keywi}">${body.keywi}</a>`;
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async created() {
    this.doFetchAll();
  }
}
