



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Model, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';

import { printDuration } from '../../shared/util/date.util';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {},
})
export default class BookingDatePicker extends Vue {
  @Model('input', {
    type: String,
  })
  date!: string;

  @Prop({
    default: false,
  })
  disabled!: boolean;

  @Prop()
  label!: string;

  @Prop({ default: 'UTC' })
  timezone!: string;

  duration = '0m';
  interval = null;

  get localizedDate() {
    if (!this.date) {
      return null;
    }
    return moment(this.date)
      .tz(this.timezone)
      .toISOString(true);
  }

  @Watch('date', { immediate: true })
  onDateChanged() {
    if(!this.date) {
      this.duration = 'N/A';
      return;
    }

    this.duration = printDuration(new Date(), this.date);
  }

  @Emit('input')
  onLocalizedDateChanged(selectedDates, dateStr, instance) {
    const ret = moment(dateStr)
      .tz(this.timezone, true)
      .toISOString();

    if (ret === this.localizedDate) {
      return false;
    }
    return ret;
  }

  created() {
    this.interval = setInterval(() => {
      this.onDateChanged();
    }, 10000);
  }
  beforeDestroy() {
    clearInterval(this.interval);
  }
}
