import { Role } from '../auth/role.enum';

export class Invite {
  project: string;
  constructor(
    public emails: string[] = [],
    public role: Role = Role.User,
    public projects?: string[],
    public company: string = '',
    public suspendAfter?: Date,
  ) {}
}
