



















import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';

import BaseTable from '../components/BaseTable.vue';
import { Column } from '../shared/types/column.class';
import { formatDate } from '../shared/util/print-column.util';
import { Prop, Watch } from 'vue-property-decorator';

const supportModule = namespace('support');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable } })
export default class SupportTable extends Vue {
  @Prop({
    required: true,
  })
  email: string;

  isLoading = false;

  columns: Column[] = [
    new Column('subject', 'Subject'),
    new Column('status', 'Status'),
    new Column('created_at', 'Created at', formatDate),
    new Column('updated_at', 'Updated at', formatDate),
  ];

  @supportModule.Getter('all')
  tickets!: any[];

  @supportModule.Action('fetchByEmail')
  fetchAll!: (email: string) => Promise<any[]>;

  @Watch('email')
  onEmailChanged() {
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll(this.email);
    this.isLoading = false;
  }

  openTicket(ticket: any) {
    window.open(
      `https://tapazz.zendesk.com/agent/tickets/${ticket.id}`,
      '_blank',
    );
  }

  async created() {
    this.doFetchAll();
  }
}
