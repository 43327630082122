































import { PersonalKey } from '@/api/personal-keys/personal-key.model';
import { Project } from '@/api/projects/project.model';
import { User } from '@/api/users/user.class';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '@/shared/types/form-element.class';
import * as _ from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Column } from '../shared/types/column.class';
import { CastType, Filter, Operator } from '../shared/types/filter.class';
import { PaginateResult } from '../shared/types/paginate-result.class';
import { formatDate, formatUser } from '../shared/util/print-column.util';
import BaseForm from './BaseForm.vue';
import BaseHeader from './BaseHeader.vue';
import BaseTable from './BaseTable.vue';

const personalKeysModule = namespace('personalKeys');
const projectsModule = namespace('projects');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, BaseForm } })
export default class PersonalKeysTable extends Vue {
  @Prop({
    required: true,
  })
  filter!: Filter;

  @Prop({
    type: Array,
  })
  columns!: Column[];

  @Prop({
    default: true,
  })
  canUpdate!: boolean;

  @Prop({
    default: true,
  })
  canDelete!: boolean;

  @Prop({
    default: false,
  })
  canSearch!: boolean;

  @Prop()
  embedded!: boolean;

  @Prop({
    default: false,
  })
  isLoading!: boolean;

  showFilters = false;
  isLoadingInternal = false;
  localFilter = { project: [] };
  filterElements: FormElement[][] = [];

  baseColumns: Column[] = [
    new Column('asset.name', 'Asset'),
    new Column('user', 'User', formatUser),
    new Column('project', 'Project', this.printProject.bind(this)),
    new Column('createdAt', 'Created At', formatDate),
  ];

  printProject(column: Column, obj: unknown) {
    const value = _.get(obj, column.field);
    const project = this.projects.find(proj => proj._id === value);

    return project?.name || 'N/A';
  }

  get displayLoader() {
    return this.isLoading || this.isLoadingInternal;
  }

  get getColumns() {
    if (!this.columns || this.columns.length === 0) {
      return this.baseColumns;
    }
    return this.columns;
  }

  get getCanDelete() {
    return this.canDelete && this.$can('delete', 'PersonalKey');
  }

  get getCanUpdate() {
    return this.canUpdate && this.$can('update', 'PersonalKey');
  }

  @usersModule.Getter('loggedInUser')
  user!: User;

  @personalKeysModule.Getter('all')
  personalKeys!: PersonalKey[];

  @projectsModule.Getter('full')
  projects!: Project[];

  @personalKeysModule.Getter('pagination')
  pagination!: PaginateResult<PersonalKey>;

  @personalKeysModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<PersonalKey[]>;

  @projectsModule.Action('fetchFull')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @personalKeysModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  get showFilterButton() {
    return true;
  }

  @Watch('filter', { deep: true })
  onFilterChanged() {
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoadingInternal = true;
    await this.fetchAllProjects();
    await this.fetchAll(this.filter);
    this.isLoadingInternal = false;
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async doDelete(personalKey: PersonalKey) {
    await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove the selected personal key?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    await this.delete(personalKey._id);
    this.doFetchAll();
  }

  onFilterClicked() {
    this.filterElements = [
      [
        new FormElement(
          'project',
          'Project',
          new FormElementOptions(this.projects, 'name', '_id'),
          '',
          FormElementType.SELECT,
        ),
      ],
    ];
    this.showFilters = true;
  }

  applyFilters() {
    this.filter.removeForKeyAndOperator('project', Operator.Equal);
    if (this.localFilter.project.length > 0) {
      this.filter.addFilter(
        'project',
        this.localFilter.project,
        Operator.Equal,
        CastType.ObjectId,
      );
    }
    this.doFetchAll();
  }

  async created() {
    this.doFetchAll();
  }
}
