



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit, Watch } from 'vue-property-decorator';
import BaseForm from '../components/BaseForm.vue';
import BookingDatePicker from '../components/BookingDetail/BookingDatePicker.vue';

import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { Booking } from '../api/bookings/booking.model';
import { namespace } from 'vuex-class';
import {
  Filter,
  FilterOperation,
  Operator,
} from '../shared/types/filter.class';
import { Asset } from '../api/assets/asset.model';
import { Project } from '../api/projects/project.model';
import { Company } from '../api/companies/company.model';
import { User } from '../api/users/user.class';

const assetsModule = namespace('assets');
const companiesModule = namespace('companies');
const projectsModule = namespace('projects');
const usersModule = namespace('users');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
    BookingDatePicker,
  },
})
export default class BookingForm extends Vue {
  @Prop()
  booking!: Booking;

  company = '';
  timezone = 'UTC';
  start = new Date().toISOString();
  end = new Date().toISOString();

  @assetsModule.Getter('all')
  assets!: Asset[];

  @projectsModule.Getter('all')
  projects!: Project[];

  @companiesModule.Getter('all')
  companies!: Company[];

  @usersModule.Getter('all')
  users!: User[];

  @usersModule.Getter('loggedInUser')
  loggedInUser!: User;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @projectsModule.Action('fetchAll')
  fetchAllProjects!: (filter?: Filter) => Promise<Project[]>;

  @companiesModule.Action('fetchAll')
  fetchAllCompanies!: (filter?: Filter) => Promise<Company[]>;

  @usersModule.Action('fetchAll')
  fetchUsers!: (filter?: Filter) => Promise<User[]>;

  @assetsModule.Action('fetchAll')
  fetchAssets!: (filter?: Filter) => Promise<Asset[]>;

  formElements: FormElement[][] = [];

  @Watch('start', { immediate: true })
  onStartChanged(newStart) {
    this.booking.start = newStart;
  }

  @Watch('end', { immediate: true })
  onEndChanged(newEnd) {
    this.booking.end = newEnd;
  }

  @Watch('booking.asset', { immediate: true })
  onAssetChanged(newValue: string) {
    const asset = this.assets.find(x => x._id === newValue);
    this.timezone = asset.timezone;
  }
  @Watch('booking.company', { immediate: true })
  async onCompanyChanged(newValue: string) {
    if (newValue === '') {
      return;
    }
    await this.fetchAllProjects(
      new Filter([new FilterOperation('company', newValue)]),
    );
    for (const outerEl of this.formElements) {
      for (const el of outerEl) {
        if (el.field === 'project') {
          el.options.optionsData = this.projects;
        }
      }
    }
  }

  @Watch('booking.project', { immediate: true })
  async onProjectChanged(newValue: string) {
    await this.fetchAssets(
      new Filter([
        new FilterOperation('project', newValue),
        new FilterOperation('limit', 500),
      ]),
    );
    await this.fetchUsers(
      new Filter([
        new FilterOperation('projects', [newValue]),
        new FilterOperation('limit', 500),
      ]),
    );
    for (const outerEl of this.formElements) {
      for (const el of outerEl) {
        if (el.field === 'asset') {
          el.options.optionsData = this.assets;
        }
        if (el.field === 'user') {
          el.options.optionsData = this.users;
        }
      }
    }
  }

  async created() {
    this.addLoader('assetForm');
    this.booking.company = this.loggedInUser.company;
    this.booking.project = this.loggedInUser.project?._id;
    this.booking.user = this.loggedInUser;
    await this.fetchUsers();
    await this.fetchAssets();
    if (this.$can('read', 'Company')) {
      await this.fetchAllCompanies();
      await this.fetchAllProjects();
      this.formElements.push([
        new FormElement(
          'company',
          'Company',
          new FormElementOptions(this.companies, 'name', '_id'),
          '',
          FormElementType.SELECT,
        ),
      ]);
      this.formElements.push([
        new FormElement(
          'project',
          'Project',
          new FormElementOptions(this.projects, 'name', '_id'),
          '',
          FormElementType.SELECT,
        ),
      ]);
    }

    this.formElements.push(
      [
        new FormElement(
          'user',
          'User',
          new FormElementOptions(this.users, 'email', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ],
      [
        new FormElement(
          'asset',
          'Asset',
          new FormElementOptions(this.assets, 'name', '_id'),
          'required',
          FormElementType.SELECT,
        ),
      ],
    );
    this.removeLoader('assetForm');
  }
}
