



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, VModel, Prop } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {},
})
export default class SelectableDropdown extends Vue {
  @Prop()
  items!: any[];

  @VModel({ type: [Array, Object] }) data!: any[];

  get isMultiselect() {
    return this.data instanceof Array;
  }

  get notSelectedItems() {
    if (!this.isMultiselect) {
      return this.items;
    }
    return this.items.filter(x => {
      const index = this.data.findIndex(
        selectedItem => selectedItem._id === x._id,
      );
      return index === -1;
    });
  }

  itemClicked(item: any) {
    if (this.data instanceof Array) {
      if (!item) {
        this.data = [];
        return;
      }
      const index = this.data.findIndex(x => item._id === x._id);
      if (index > -1) {
        this.data.splice(index, 1);
        return;
      }
      this.data.push(item);
      return;
    }
    this.data = item;
  }
}
