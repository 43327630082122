
































































































import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { namespace } from 'vuex-class';
import SelectableDropdown from '../components/SelectableDropdown.vue';
import DateRangeSelect, {
  DateRange,
  DateRangeEnum,
} from '../components/DateRangeSelect.vue';

import { User } from '../api/users/user.class';
import { CastType, Filter, Operator } from '../shared/types/filter.class';
import { Asset } from '../api/assets/asset.model';
import { Project } from '../api/projects/project.model';
import { Watch } from 'vue-property-decorator';
import { Company } from '@/api/companies/company.model';

const usersModule = namespace('users');
const projectsModule = namespace('projects');
const assetsModule = namespace('assets');
const companiesModule = namespace('companies');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    SelectableDropdown,
    DateRangeSelect,
  },
})
export default class Awards extends Vue {
  dateRange = new DateRange(DateRangeEnum.Month);
  selectedCompany = null;
  selectedProjects: Project[] = [];
  filterToday = false;

  @usersModule.Getter('loggedInUser')
  currentUser!: User;

  @companiesModule.Getter('all')
  companies!: Company[];

  @usersModule.Getter('mostBooked')
  mostBookedUsers!: any[];

  @assetsModule.Getter('mostBooked')
  mostBookedAssets!: any[];

  @projectsModule.Getter('pagination')
  projectPagination!: any;

  @projectsModule.Getter('all')
  projects!: Project[];

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @usersModule.Action('mostBooked')
  fetchMostBookedUsers!: (filter?: Filter) => Promise<any[]>;

  @assetsModule.Action('mostBooked')
  fetchMostBookedAssets!: (filter?: Filter) => Promise<any[]>;

  @projectsModule.Action('fetchAll')
  fetchProjects!: (filter?: Filter) => Promise<Project[]>;

  @companiesModule.Action('fetchAll')
  fetchCompanies!: (filter?: Filter) => Promise<Company[]>;

  get currentCompany() {
    return this.selectedCompany ? this.selectedCompany.name : 'All companies';
  }

  get currentProject() {
    if (this.selectedProjects.length === 0) {
      return 'All projects';
    }
    if (this.selectedProjects.length === 1) {
      return this.selectedProjects[0].name;
    }
    return 'Multiple projects';
  }

  get isSuperAdmin() {
    return true;
  }

  get filterStart() {
    return this.dateRange.start;
  }

  get filterStop() {
    return this.dateRange.stop;
  }

  @Watch('dateRange', { deep: true })
  @Watch('selectedProjects')
  onTimeframeChanged() {
    this.doFetchMostBookedAssets();
    this.doFetchMostBookedUsers();
  }

  async doFetchMostBookedAssets() {
    this.addLoader('assets');
    const filter = new Filter();
    filter.addFilter('start', this.filterStart, Operator.GreaterThanOrEqual);
    filter.addFilter('updatedAt', this.filterStop, Operator.LessThanOrEqual);
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }

    await this.fetchMostBookedAssets(filter);
    this.removeLoader('assets');
  }

  async doFetchMostBookedUsers() {
    this.addLoader('users');
    const filter = new Filter();
    filter.addFilter('start', this.filterStart, Operator.GreaterThanOrEqual);
    filter.addFilter('updatedAt', this.filterStop, Operator.LessThanOrEqual);
    if (this.selectedProjects.length > 0) {
      filter.addFilter(
        'project',
        this.selectedProjects.map(x => x._id),
        undefined,
        CastType.ObjectId,
      );
    }

    await this.fetchMostBookedUsers(filter);
    this.removeLoader('users');
  }

  created() {
    this.fetchCompanies();
    this.fetchProjects();
    this.doFetchMostBookedUsers();
    this.doFetchMostBookedAssets();
  }
}
